import { Component, OnInit,HostListener, ElementRef,ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ApiServiceBackEnd } from 'src/app/services/apiBackend.service';
import { DateFormatService } from 'src/app/services/date-format.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {
  @ViewChild('successOpenBtn') successOpenBtn!: ElementRef;
  @ViewChild('successCloseBtn') successCloseBtn!: ElementRef;
  @ViewChild('successOpenExpiryBtn') successOpenExpiryBtn!: ElementRef;

  
  
  patientId: any;
  currentCustomer: any = {};
  nextDayDeliverd: boolean = false;
  secondDayDeliverd: boolean = false;
  deliveryOption: string = "";
  changeInSupplyQuantity: any
  leftLensQuantity: any;
  rightLensQuantity: any;
  is12Month:boolean=false;
  loading: boolean = false;
  success:boolean=false
  currentDate: Date = new Date();
  activeSection1:boolean=false;
  activeSection2:boolean=false;
  activeSection3:boolean=false;
  activeSection4:boolean=false;
  activeSection5:boolean=false;
  activeSection0:boolean=false;
  errorMsg:string=""
  updatedHomeAdd:any
  deliveryLocation:any
  updatedData:any
  divError:string=""
  inputDOB:string=''
  dobError:boolean=false
  userEmail:string=""
  userPhone:any
  rightEyeBox:number=0
  leftEyeBox:number=0
  totalPrice:number=0
  patientTimeZone:any
  public sectionIds: string[] = ['section1', 'section2', 'section3','section4']; // Add more section IDs as needed
  public activeSection: string = '';
  paymentIntent: any;
  constructor(
    private api: ApiService,
    private api2: ApiServiceBackEnd,
    private route: ActivatedRoute,
    private el: ElementRef,
    private dateFormatService: DateFormatService
    

  ) { }


  ngOnInit(): void {
    this.route.url.subscribe(segments => {
      // segments is an array of URL segments
      const lastSegment = segments[segments.length - 1];
      const valueAfterLastSlash = lastSegment.path; // This is the value after the last slash
      this.patientId = valueAfterLastSlash
    });
    if (this.patientId) {
      this.getPatientReorderSetting()
    }
    var timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone
    this.patientTimeZone=timeZone
    console.log("timeZone",timeZone)
  }

  isValidDOB() {
    var result= /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/.test(this.inputDOB);
    if(result==true){
       this.dobError=false
       this.macthDateofBirth()
     }else{
      this.dobError=true
     }
  }
  formatInputDOB() {
    const cleanedInput = this.inputDOB.replace(/[^a-zA-Z0-9]/g, '');
    console.log("this.cleanedInput.length",cleanedInput.length)
    console.log("this.cleanedInput",cleanedInput)

    if (this.inputDOB) {
      // Remove non-numeric characters
      const cleanedInput = this.inputDOB.replace(/[^0-9]/g, '');

      // Ensure the length does not exceed 8 characters
      if (cleanedInput.length > 8) {
        this.inputDOB = cleanedInput.slice(0, 8);
        this.inputDOB = this.inputDOB.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');

      } else {
        // Format the inputDOB as MM/DD/YYYY
        this.inputDOB = cleanedInput.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
      }
    }
  }

  macthDateofBirth(){
    let userDob= this.currentCustomer.patientInfo.Patient_Date_of_Birth
    if(userDob==this.inputDOB){
      this.successCloseBtn.nativeElement.click()
      // this.scrollToTop();
      // this.scrollToDiv('section1')
      console.log("buid is up")
      localStorage.setItem('matchDob',"matched")
      localStorage.setItem(this.patientId,'matched')
      window.location.reload();

    }else{
      alert("Date of Birth Not Matched")
    }  
  }
  onSubmit(){
    this.isValidDOB()
  }

  getPatientReorderSetting() {

    if (this.deliveryOption == "secondDay") {
      this.secondDayDeliverd = true;
      this.nextDayDeliverd = false;
    }
    else if (this.deliveryOption == "nextDay") {
      this.secondDayDeliverd = false;
      this.nextDayDeliverd = true;
    }
    else if (this.deliveryOption == "standard") {
      this.secondDayDeliverd = false;
      this.nextDayDeliverd = false;
    }
    else {
      this.secondDayDeliverd = false;
      this.nextDayDeliverd = false;
    }

    if (this.changeInSupplyQuantity) {
      this.api.getData(`getLastJobInfo?reorderId=${this.patientId}&deliveryType=${this.deliveryOption?this.deliveryOption:"standard"}&twelveMonthSupply=${this.is12Month}&isDirectToOffice=${this.deliveryLocation=="office"?true:false}&rightEyeBox=${this.rightEyeBox}&leftEyeBox=${this.leftEyeBox}`, {}).subscribe((r: any) => {
        this.currentCustomer = r.data
        this.totalPrice= parseFloat(this.currentCustomer.paymentSummary.leftEyePrice) + parseFloat(this.currentCustomer.paymentSummary.rightEyePrice)
      });
    } else {
      this.api.getData(`getLastJobInfo?reorderId=${this.patientId}&deliveryType=${this.deliveryOption?this.deliveryOption:"standard"}&isDirectToOffice=${this.deliveryLocation=="office"?true:false}&rightEyeBox=${this.rightEyeBox}&leftEyeBox=${this.leftEyeBox}`, {}).subscribe((r: any) => {
        this.currentCustomer = r.data
        var a=r.data.paymentSummary
       this.rightEyeBox=r.data.rightEyeData?.Quantity_12_Month
       this.leftEyeBox=r.data.leftEyeData?.Quantity_12_Month
       this.totalPrice= parseFloat(this.currentCustomer.paymentSummary.leftEyePrice) + parseFloat(this.currentCustomer.paymentSummary.rightEyePrice)

        // var checkDob=localStorage.getItem('matchDob')
        var checkDob= localStorage.getItem(this.patientId)
        console.log("this.currentCustomer.isExpired",this.currentCustomer.isExpired)
        if(this.currentCustomer.isExpired){
          this.successOpenExpiryBtn.nativeElement.click()
        }else{
          if(checkDob=="matched"){

          }else if(this.currentCustomer.isConverted){
            this.success=true
            this.activeSection0=true
            this.activeSection2=true;
            this.activeSection3=true;
            this.activeSection4=true;
           }
           else{
              this.successOpenBtn.nativeElement.click();
           }
        }
       
        
        var b={"orderId":this.patientId}
        var c={"patientTimeZone":this.patientTimeZone}
        const combinedSettings = Object.assign({}, a, b,c);
         this.updatedData=combinedSettings
         console.log(">>>>>>",this.updatedData)
         if(this.currentCustomer.isConverted){
          this.success=true
          this.activeSection0=true
          this.activeSection2=true;
          this.activeSection3=true;
          this.activeSection4=true;
         }
         if(this.currentCustomer.patientInfo.Patient_Email_Address.length>1 && this.currentCustomer.patientInfo.Patient_Primary_Phone.length>1){
          this.activeSection0=true
          this.userEmail=this.currentCustomer.patientInfo.Patient_Email_Address;
          this.userPhone=this.currentCustomer.patientInfo.Patient_Primary_Phone
         }
      });
    }

  }

  changeInSupply(event: any) {
    this.activeSection2=true;
    this.changeInSupplyQuantity = event
    if (this.changeInSupplyQuantity) {
      this.leftLensQuantity = this.changeInSupplyQuantity.leftEyeQuantity
      this.rightLensQuantity = this.changeInSupplyQuantity.rightEyeQuantity

      this.rightEyeBox=this.changeInSupplyQuantity.rightEyeQuantity
      this.leftEyeBox=this.changeInSupplyQuantity.leftEyeQuantity

    }
    if(this.changeInSupplyQuantity.supplyMonth=="12 months"){
      this.is12Month=true
    }else{
      this.is12Month=false
    }
    this.getPatientReorderSetting()
  }

  changeInDeliveryOption(event: any) {
    // this.activeSection3=true;
    this.deliveryOption = event
    this.getPatientReorderSetting()
    if(this.deliveryLocation === undefined || this.deliveryLocation === null || this.deliveryLocation === ""){
      
    }else{
      this.activeSection3=true;
    }
  }

  changeInformation(event:any){
this.userEmail=event.email
this.userPhone=event.phoneNumber
if(this.userEmail.length>0 || this.userPhone){
  this.activeSection0=true
}else{
  this.activeSection0=false

}
  }

  changeInDeliveryAddress(event:any){
    // this.activeSection3=true;
    var a=event.updatedHomeAddress
    var b=this.currentCustomer.paymentSummary
    var c={"reorderId":this.patientId}
    var e={"lastOrderId":this.currentCustomer._id}
    var d={"patientTimeZone":this.patientTimeZone}
    this.deliveryLocation=event.location
    const combinedSettings = Object.assign({}, a, b,c,d,e);
    this.updatedHomeAdd=combinedSettings
    if(this.deliveryOption.length>0){
      this.activeSection3=true;
    }
    this.getPatientReorderSetting()
  }

  checkValidCard(event:any){
    if(event.complete){
      this.activeSection4=true
    }
   
    else{
      this.activeSection4=false
    }
  }
  cardTokenDetail(event: any) {
    console.log("cardTokenDetail event>>>",event)
    console.log("cardTokenDetail event>>>",event.error?.code)
    console.log("event.billing_name",event.billing_name)

    var a={"paymentMethod":event?.paymentMethod?.card.brand}
    var b={ "cardLastNumber":event?.paymentMethod?.card.last4}
    var c=this.updatedHomeAdd
    var d={"patientTimeZone":this.patientTimeZone}

    const combinedSettings = Object.assign({}, a, b,c,d);
    this.updatedHomeAdd=combinedSettings
    console.log("final combinedSettings",this.updatedHomeAdd)
  
  
   if(!this.activeSection2){
      this.errorMsg="Please choose an appropriate supply amount."
      this.divError="section2"
      this.scrollToTop()
      this.currentDate = new Date();
    }
   
    else if ( this.deliveryLocation === undefined || this.deliveryLocation === null || this.deliveryLocation === "") {
      this.errorMsg="Please select your delivery address."
      this.divError="section3"
      this.scrollToTop()
      this.currentDate = new Date();
     
    }
    else if(!this.deliveryOption || this.deliveryOption.length == 0 || this.deliveryOption == ""){
      this.errorMsg="Please select your desired ship speed."
      this.divError="section3"
      this.scrollToTop()
      this.currentDate = new Date();
    }
   else if(!this.activeSection0 &&( !this.userEmail || !this.userPhone)){
      this.errorMsg="Please check contact information."
      this.divError="section0"
      this.scrollToTop()
      this.currentDate = new Date();
    }
    else if(event.billing_name==""|| event.billing_name==undefined){
      console.log("in billing>>>>>")
      this.errorMsg="Please enter the appropriate card holder name."
      this.divError="section4"
      this.currentDate = new Date();
     
      this.api2.errorEmit({
        type:'section4',
        subType:'section4_name',
        msg:"Please check card Number"
      })
      this.scrollToTop()
      this.currentDate = new Date();
    }
    else if(event.error?.code==="incomplete_number"){
      this.errorMsg="Please provide the card details."
      this.divError="section4"
      this.api2.errorEmit({
        type:'section4',
        msg:"Please check card details",
        subType:'section4Number'
      })
      this.scrollToTop()
      this.currentDate = new Date();
    }

    else if(event.error?.code){
      this.errorMsg="The provided card details failed. Please adjust and resubmit accordingly."
      this.divError="section4"
      this.api2.errorEmit({
        type:'section4',
        msg:"Please check card details",
      })
      this.scrollToTop()
      this.currentDate = new Date();
    } 
    else{
      this.errorMsg=""
      this.divError=""
      this.api2.errorEmit({
        type:'',
        msg:""
      })
       this.paymentStripe(event.paymentMethod.id)
    }
  }

  postReorderSetting() {
    var deliverLocation;
    if(this.deliveryLocation=="home"){
      deliverLocation='Patient'
    }else if(this.deliveryLocation=="office"){
      deliverLocation='Office'
    }else{
      deliverLocation=this.currentCustomer.Soft_CL_Ship_To
    }
      let input: any = {};
    input.Enterprise_ID = this.currentCustomer.enterpriseId;
    input.businessType="Reorder"
    input.Patient_First_Name = this.currentCustomer.patientInfo.Patient_First_Name
    input.Patient_Last_Name = this.currentCustomer.patientInfo.Patient_Last_Name
    input.Patient_Gender = this.currentCustomer.patientInfo.Patient_Gender
    input.Patient_Date_of_Birth = this.currentCustomer.patientInfo.Patient_Date_of_Birth
    input.Patient_Address_1 = this.currentCustomer.patientInfo.Patient_Address_1
    input.Patient_Address_2 = this.currentCustomer.patientInfo.Patient_Address_2
    input.Patient_Zip_Code = this.currentCustomer.patientInfo.Patient_Zip_Code
    input.Patient_City = this.currentCustomer.patientInfo.Patient_City
    input.Patient_State = this.currentCustomer.patientInfo.Patient_State
    input.Patient_Country = this.currentCustomer.patientInfo.Patient_Country
    // input.Patient_Primary_Phone=""
    input.Patient_Primary_Phone = this.currentCustomer.patientInfo.Patient_Primary_Phone==""?this.userPhone:this.currentCustomer.patientInfo.Patient_Primary_Phone
    input.Patient_Primary_Phone_Type = this.currentCustomer.patientInfo.Patient_Primary_Phone_Type
    input.Patient_Secondary_Phone = this.currentCustomer.patientInfo.Patient_Secondary_Phone
    input.Patient_Secondary_Phone_Type = this.currentCustomer.patientInfo.Patient_Secondary_Phone_Type
    input.Patient_Email_Address = this.currentCustomer.patientInfo.Patient_Email_Address==""?this.userEmail:this.currentCustomer.patientInfo.Patient_Email_Address
    // input.Patient_Email_Address = "jared@arrellio.com"
    input.Patient_Text_Order_Status_Opt_In = this.currentCustomer.patientInfo.Patient_Text_Order_Status_Opt_In
    input.Patient_Email_Order_Status_Opt_In = this.currentCustomer.patientInfo.Patient_Email_Order_Status_Opt_In
    input.Patient_Point_of_Sale_Patient_ID = this.currentCustomer.patientInfo.Patient_Point_of_Sale_Patient_ID
    input.Soft_CL_Order_Date = this.currentCustomer.Soft_CL_Order_Date
    input.Soft_CL_Order_Store_Number = this.currentCustomer.Soft_CL_Order_Store_Number
    input.Soft_CL_Point_of_Sale_Order_Number= Date.now()
    input.Soft_CL_Original_Point_of_Sale_Number_For_Remake = this.currentCustomer.Soft_CL_Original_Point_of_Sale_Number_For_Remake
    input.Soft_CL_Sales_Amount = this.currentCustomer.Soft_CL_Sales_Amount
    input.Soft_CL_Payment_Amount = this.currentCustomer.Soft_CL_Sales_Amount
    input.Soft_CL_Lens_Right_Manufacturer = this.currentCustomer.Soft_CL_Lens_Right_Manufacturer
    input.Soft_CL_Lens_Right_Style = this.currentCustomer.Soft_CL_Lens_Right_Style
    input.Soft_CL_Lens_Right_Base = this.currentCustomer.Soft_CL_Lens_Right_Base
    input.Soft_CL_Lens_Right_Diameter = this.currentCustomer.Soft_CL_Lens_Right_Diameter
    input.Soft_CL_Lens_Right_Color = this.currentCustomer.Soft_CL_Lens_Right_Color
    input.Soft_CL_Lens_Right_Sphere = this.currentCustomer.Soft_CL_Lens_Right_Sphere
    input.Soft_CL_Lens_Right_Cylinder = this.currentCustomer.Soft_CL_Lens_Right_Cylinder
    input.Soft_CL_Lens_Right_Axis = this.currentCustomer.Soft_CL_Lens_Right_Axis
    input.Soft_CL_Lens_Right_Add = this.currentCustomer.Soft_CL_Lens_Right_Add
    input.Soft_CL_Lens_Right_Quantity = this.currentCustomer.Soft_CL_Lens_Right_Quantity
    input.Soft_CL_Lens_Right_Image = this.currentCustomer.Soft_CL_Lens_Right_Image;
    input.Soft_CL_Lens_Left_Manufacturer = this.currentCustomer.Soft_CL_Lens_Left_Manufacturer
    input.Soft_CL_Lens_Left_Style = this.currentCustomer.Soft_CL_Lens_Left_Style
    input.Soft_CL_Lens_Left_Base = this.currentCustomer.Soft_CL_Lens_Left_Base
    input.Soft_CL_Lens_Left_Diameter = this.currentCustomer.Soft_CL_Lens_Left_Diameter
    input.Soft_CL_Lens_Left_Color = this.currentCustomer.Soft_CL_Lens_Left_Color
    input.Soft_CL_Lens_Left_Sphere = this.currentCustomer.Soft_CL_Lens_Left_Sphere
    input.Soft_CL_Lens_Left_Cylinder = this.currentCustomer.Soft_CL_Lens_Left_Cylinder
    input.Soft_CL_Lens_Left_Axis = this.currentCustomer.Soft_CL_Lens_Left_Axis
    input.Soft_CL_Lens_Left_Add = this.currentCustomer.Soft_CL_Lens_Left_Add
    input.Soft_CL_Lens_Left_Quantity = this.currentCustomer.Soft_CL_Lens_Left_Quantity
    input.Soft_CL_Lens_Left_Image = this.currentCustomer.Soft_CL_Lens_Left_Image;
    input.Soft_CL_Consultant = this.currentCustomer.Soft_CL_Consultant
    input.Soft_CL_Consultant_ID = this.currentCustomer.Soft_CL_Consultant_ID
    input.Soft_CL_Ship_To = deliverLocation
    input.Soft_CL_Supply_Source = this.currentCustomer.Soft_CL_Supply_Source
    input.Soft_CL_Instructions = this.currentCustomer.Soft_CL_Instructions
    input.Soft_CL_Parts_to_Supply_Right_Lens = this.currentCustomer.Soft_CL_Parts_to_Supply_Right_Lens
    input.Soft_CL_Parts_to_Supply_Left_Lens = this.currentCustomer.Soft_CL_Parts_to_Supply_Left_Lens
    input.Soft_CL_Dispensing_Notes = this.currentCustomer.Soft_CL_Dispensing_Notes
    input.Soft_CL_Associated_Rx_Start_Date = this.currentCustomer.Soft_CL_Associated_Rx_Start_Date
    input.Soft_CL_Associated_Rx_Expiration_Date = this.currentCustomer.Soft_CL_Associated_Rx_Expiration_Date
    // input.Soft_CL_Associated_Rx_Expiration_Date = "2/15/2026"
    input.deliveryTime = this.deliveryOption
    input.updatedData=this.updatedHomeAdd?this.updatedHomeAdd:this.updatedData
    input.invoiceNumber=this.paymentIntent

    var data = {
      jobData: input
    }
    this.divError=""
    this.api2.postData("new-jared-job-enterprise-external", data).subscribe((r: any) => {
      // this.successOpenBtn.nativeElement.click();
      this.success=true
      this.scrollToTop()
      this.loading = false
      this.errorMsg=""
      this.divError=""
      this.currentDate = new Date();
      localStorage.removeItem("matchDob")
      localStorage.removeItem(this.patientId)
      setTimeout(() => {
        this.getPatientReorderSetting()
      }, 2000);

    },
      (error: any) => {
        // Error handling
        this.errorMsg=error
        this.loading = false;
        this.success=false
        this.api2.errorEmit({
          type:'',
          msg:"Please check card details"
        })
        // Handle the error as needed (e.g., show an error message to the user)
      });
  }

  paymentStripe(cardToken: any) {
    this.loading = true
    let input: any = {};
    input.cardToken = cardToken
    input.orderId = this.currentCustomer._id,
      input.amount = this.currentCustomer.paymentSummary.Total_Due_Today
    this.api.postData("stripePayment", input).subscribe((r: any) => {
      if(r.error){
        this.errorMsg="The provided card details failed. Please adjust and resubmit accordingly."
        this.divError="section4"
        this.api2.errorEmit({
          type:'section4',
          msg:"Please check card details",
        })
        this.scrollToTop()
        this.loading = false;
      }else{
        this.paymentIntent=r.data
        this.postReorderSetting()

      }
      

    },
      (error: any) => {
        // Error handling
        console.error("Error during Stripe payment:", error);
        this.loading = false;
        // Handle the error as needed (e.g., show an error message to the user)
      }

    );
  }

  scrollToDiv(divId: string) {
    const targetElement = this.el.nativeElement.querySelector(`#${divId}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
